<template>
  <div class="DAV-workshop__trunk">
    <component-title color="green" @goPrev="goPrev" />
    <div class="DAV-blockClass">
      <div class="sub-title">진열 관리</div>
      <div class="frmBlock__layout">
        <draggable
          :list="list"
          delayOnTouchOnly="true"
          ghostClass="DAV-block__item--active"
          handle=".DAV-block__supports--move"
          animation="200"
          class="draggable-wrap"
          delay="100"
          @end="updateValue()"
        >
          <div
            v-for="(item, index) in list"
            :key="index"
            class="DAV-block__item"
            :class="{'DAV-block__item--hide': !item.enabled}"
          >
            <div class="DAV-block__bullet">
              <img
                v-if="item.imgUrls"
                class="DAV-block_img"
                :src="item.imgUrls"
              />
              <img v-else src="@/assets/images/common/icon_logo_grey.svg" />
              <span>컴포넌트 숨김</span>
            </div>
            <div class="DAV-block__info">
              <div class="DAV-block__info--no">
                {{ item.name }}
              </div>
              <ul class="DAV-block__info--text">
                <li class="no">{{ item.id }}</li>
                <li class="line"></li>
                <li :class="{sale: item.salesStatus === '판매중'}">
                  {{ item.salesStatus }}
                </li>
                <li class="line"></li>
                <li :class="{display: item.isDisplay === '전시'}">
                  {{ item.isDisplay }}
                </li>
              </ul>
            </div>
            <div class="DAV-block__supports">
              <button
                class="DAV-block__supports--move"
                oncontextmenu="return false"
              >
                이동
              </button>
              <button
                class="DAV-block__supports--option"
                @click="toggleOption(index, true)"
              >
                컴포넌트 옵션 열기
              </button>
            </div>
            <transition name="slide" mode="out-in">
              <div class="DAV-block__options" v-show="optionVisible[index]">
                <button
                  class="DAV-block__options--delete"
                  @click="productDelete(index)"
                >
                  <span>컴포넌트 삭제</span>
                </button>
                <button
                  class="DAV-block__options--display"
                  @click="productEnabled(index, !item.enabled)"
                >
                  <span>컴포넌트 숨김</span>
                </button>
                <button
                  class="DAV-block__options--close"
                  @click="toggleOption(index, false)"
                >
                  <span>컴포넌트 옵션 닫기</span>
                </button>
              </div>
            </transition>
          </div>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
const ModuleEditor = createNamespacedHelpers("ModuleEditor");
import draggable from "vuedraggable";

export default {
  data() {
    return {
      optionVisible: {},
      list: []
    };
  },
  computed: {
    ...ModuleEditor.mapGetters(["getPopup"])
  },
  mounted() {
    this.list = this.getPopup.value;
  },
  methods: {
    // 이전 리스트로 이동
    goPrev() {
      this.$store.commit("ModuleEditor/setPopup", {
        page: null
      });
    },
    // 컴포넌트 설정 toggle
    toggleOption(index, value) {
      this.optionVisible = {};
      this.$set(this.optionVisible, index, value);
    },
    // update value
    updateValue() {
      this.$EventBus.$emit(
        `productUpdateValue${this.getPopup.option.id}`,
        this.list
      );
    },
    // product 삭제
    productDelete(i) {
      this.optionVisible = {};
      this.list.splice(i, 1);
      this.updateValue();
    },
    // product 숨김 처리
    productEnabled(i, v) {
      this.optionVisible = {};
      this.list[i].enabled = v;
      this.updateValue();
    }
  },
  components: {
    draggable,
    ComponentTitle: () => import("@/components/editor/ComponentTitle.vue")
  }
};
</script>

<style lang="scss" scoped>
.sub-title {
  background: #fff;
  font-weight: 500;
  font-size: 14px;
  padding: 24px 16px 8px;
}

.frmBlock__layout {
  margin-bottom: 24px;
  padding: 0 16px;
}

.draggable-wrap {
  width: 100%;

  .DAV-block {
    &__name {
      flex-grow: 1;
      text-align: left;
      @include text-ellipsis;
    }
  }
  .DAV-block__item {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    height: 81px;
    margin-top: 8px;
    border: 1px solid #dcdcdc;
    box-sizing: border-box;
    @include box;

    &:first-child {
      margin-top: 0px;
    }

    &--active {
      z-index: 1 !important;
      border: 0 !important;
      box-shadow: 0px 0px 8px #54c7a2 !important;

      & .DAV-block__supports--move {
        background-image: url($path + "ico_move_active.svg");
      }
    }
    &--hide {
      .DAV-block__bullet span {
        display: block;
      }
      .DAV-block__options--display span {
        background-image: url($path + "ico_opt_display_show.svg");
      }
    }
  }
  .DAV-block__bullet {
    flex-shrink: 0;
    position: relative;
    width: 64px;
    height: 64px;
    margin: 0 16px;
    background-color: #f0f0f0;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      background-color: rgba(63, 63, 63, 0.4);
      background-image: url($path + "ico_opt_display_hide.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 16px auto;
      text-indent: -9999px;
    }

    .DAV-block_img {
      width: 64px;
      height: 64px;
      border-radius: 12px;
    }
  }
  .DAV-block__info {
    flex-grow: 1;
    overflow: hidden;

    &--no {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &--text {
      li {
        display: inline-block;
        color: #b7b7b7;
      }

      .line {
        width: 1px;
        height: 12px;
        background: #dcdcdc;
        margin: 0 7px;
      }

      .no {
        color: #8d8d8d;
      }

      .sale {
        color: #3366ff;
      }

      .display {
        color: #ed5076;
      }
    }
  }
  .DAV-block__supports {
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    margin-right: 8px;
    margin-left: auto;
    width: 81px;
    height: 24px;
    background-image: url($path + "bg_vline.svg");
    background-repeat: no-repeat;
    background-position: 50% 0;

    &--move {
      width: 24px;
      height: 24px;
      background-image: url($path + "ico_move.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      text-indent: -9999px;
      cursor: pointer;
      -webkit-touch-callout: none;
    }
    &--option {
      width: 24px;
      height: 24px;
      background-image: url($path + "bul_arr_next.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      text-indent: -9999px;
    }
  }
  .DAV-block__options {
    z-index: 1;
    display: flex;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 152px;
    background-color: #985fe8;
    border-radius: 8px;

    button {
      float: left;
      height: 100%;
      background-image: url($path + "bg_vline_purple.svg");
      background-repeat: no-repeat;
      background-position: 0 50%;
      span {
        display: block;
        height: 100%;
        text-indent: -9999px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      }
      &:first-child,
      &:last-child {
        background: 0;
      }
    }
    &--delete {
      width: 35%;

      span {
        background-image: url($path + "ico_opt_delete.svg");
      }
    }
    &--display {
      width: 35%;

      span {
        background-image: url($path + "ico_opt_display_hide.svg");
      }
    }
    &--close {
      width: 30%;

      span {
        background-color: #484948;
        background-image: url($path + "ico_opt_close.svg");
        border-radius: 0px 8px 8px 0px;
      }
    }
  }
}

/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
  .DAV-workshop__heading {
    h3 {
      padding-left: 32px;
    }
  }
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
  .DAV-workshop__heading {
    display: none !important;
  }
}
</style>

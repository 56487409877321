var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "DAV-workshop__trunk"
  }, [_c('component-title', {
    attrs: {
      "color": "green"
    },
    on: {
      "goPrev": _vm.goPrev
    }
  }), _c('div', {
    staticClass: "DAV-blockClass"
  }, [_c('div', {
    staticClass: "sub-title"
  }, [_vm._v("진열 관리")]), _c('div', {
    staticClass: "frmBlock__layout"
  }, [_c('draggable', {
    staticClass: "draggable-wrap",
    attrs: {
      "list": _vm.list,
      "delayOnTouchOnly": "true",
      "ghostClass": "DAV-block__item--active",
      "handle": ".DAV-block__supports--move",
      "animation": "200",
      "delay": "100"
    },
    on: {
      "end": function end($event) {
        return _vm.updateValue();
      }
    }
  }, _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "DAV-block__item",
      class: {
        'DAV-block__item--hide': !item.enabled
      }
    }, [_c('div', {
      staticClass: "DAV-block__bullet"
    }, [item.imgUrls ? _c('img', {
      staticClass: "DAV-block_img",
      attrs: {
        "src": item.imgUrls
      }
    }) : _c('img', {
      attrs: {
        "src": require("@/assets/images/common/icon_logo_grey.svg")
      }
    }), _c('span', [_vm._v("컴포넌트 숨김")])]), _c('div', {
      staticClass: "DAV-block__info"
    }, [_c('div', {
      staticClass: "DAV-block__info--no"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('ul', {
      staticClass: "DAV-block__info--text"
    }, [_c('li', {
      staticClass: "no"
    }, [_vm._v(_vm._s(item.id))]), _c('li', {
      staticClass: "line"
    }), _c('li', {
      class: {
        sale: item.salesStatus === '판매중'
      }
    }, [_vm._v(" " + _vm._s(item.salesStatus) + " ")]), _c('li', {
      staticClass: "line"
    }), _c('li', {
      class: {
        display: item.isDisplay === '전시'
      }
    }, [_vm._v(" " + _vm._s(item.isDisplay) + " ")])])]), _c('div', {
      staticClass: "DAV-block__supports"
    }, [_c('button', {
      staticClass: "DAV-block__supports--move",
      attrs: {
        "oncontextmenu": "return false"
      }
    }, [_vm._v(" 이동 ")]), _c('button', {
      staticClass: "DAV-block__supports--option",
      on: {
        "click": function click($event) {
          return _vm.toggleOption(index, true);
        }
      }
    }, [_vm._v(" 컴포넌트 옵션 열기 ")])]), _c('transition', {
      attrs: {
        "name": "slide",
        "mode": "out-in"
      }
    }, [_c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.optionVisible[index],
        expression: "optionVisible[index]"
      }],
      staticClass: "DAV-block__options"
    }, [_c('button', {
      staticClass: "DAV-block__options--delete",
      on: {
        "click": function click($event) {
          return _vm.productDelete(index);
        }
      }
    }, [_c('span', [_vm._v("컴포넌트 삭제")])]), _c('button', {
      staticClass: "DAV-block__options--display",
      on: {
        "click": function click($event) {
          return _vm.productEnabled(index, !item.enabled);
        }
      }
    }, [_c('span', [_vm._v("컴포넌트 숨김")])]), _c('button', {
      staticClass: "DAV-block__options--close",
      on: {
        "click": function click($event) {
          return _vm.toggleOption(index, false);
        }
      }
    }, [_c('span', [_vm._v("컴포넌트 옵션 닫기")])])])])], 1);
  }), 0)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }